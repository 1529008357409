main {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button {
  color: white;
  background-color: #4f46e5;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-radius: 0.3rem;
  width: 12rem;
  height: 3rem;
  -webkit-appearance: button;
}

.button,
.button:focus,
.button:active,
.button:hover {
  outline: none;
  cursor: pointer;
}

h1 {
  color: rgba(31, 41, 55, 1);
  text-align: center;
}

img {
  min-width: 100px;
  max-width: 400px;
}
